import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
} from "@chakra-ui/react";
const WaitListModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent bg="brand.green" color={"brand.white"}>
        <ModalHeader fontSize={"2xl"}>Hey There 😊</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize={"1.5rem"}>
            Thank you for your interest in the Merielite program. We are sorry
            entries are closed at the moment!
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={onClose}
            bg="brand.green"
            color={"brand.white"}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default WaitListModal;
