import React from "react";
import { Flex, Icon, Image, Link, Stack, Text } from "@chakra-ui/react";
import logo from "../assets/meristem-logo.svg";
import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";

export const Footer = () => {
  return (
    <Stack alignItems={"center"}>
      <Stack
        w={"80%"}
        h={{ base: "auto", lg: "150px" }}
        alignItems={"center"}
        justifyContent={"space-around"}
        borderBottomWidth={{ base: "none", lg: "1px" }}
        borderBottomStyle={"solid"}
        borderBottomColor={"#86878B"}
        my={{ base: "50px", lg: "30px" }}
        textAlign={"center"}
      >
        <Link href="https://meristemng.com" isExternal>
          <Image h={45} objectFit="cover" src={logo} alt="Meristem logo" />
        </Link>
      </Stack>
      <Flex
        w={"80%"}
        h={{ base: "100px", lg: "auto" }}
        direction={{ base: "column", lg: "row" }}
        alignItems={"center"}
        justifyContent={{ base: "space-around", lg: "space-between" }}
        color={"brand.textblack"}
      >
        <Text fontSize={"12px"}>
          © 2022 Meristem Securities limited. All rights reserved
        </Text>
        <Flex w={{ base: "50%", lg: "auto" }} justifyContent={"space-between"}>
          <Link href="www.instagram.com/meristemng/" isExternal>
            <Icon as={AiOutlineInstagram} w={8} h={8} color={"brand.green"} />
          </Link>

          <Link
            href="https://www.linkedin.com/company/meristem-securities-limited/mycompany/"
            isExternal
          >
            <Icon as={AiFillLinkedin} w={8} h={8} color={"brand.green"} />
          </Link>
        </Flex>
      </Flex>
    </Stack>
  );
};
