import {
  Box,
  Button,
  Heading,
  Link,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import WaitListModal from "./WaitlistModal";

export const ApplicationCTA = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Stack w={"100%"} py={"50px"} alignItems={"center"}>
      <WaitListModal isOpen={isOpen} onClose={onClose} />
      <Stack
        pos={"relative"}
        w={"80%"}
        h={{ base: "250px", lg: "370px" }}
        justifyContent={{ base: "space-around", lg: "center" }}
        alignItems={"center"}
        color={"brand.textblack"}
        textAlign={"center"}
        bg={"brand.lightgreen"}
        borderRadius={{ base: "15px", lg: "4px" }}
        spacing={{ base: 2, lg: 8 }}
        overflow={"hidden"}
      >
        <Box
          pos={"absolute"}
          top={0}
          left={0}
          display={{ base: "none", lg: "block" }}
          bg={"brand.green"}
          boxSize={"100px"}
          style={{ clipPath: "polygon(0 0, 100% 0%,  0 100%)" }}
        ></Box>
        <Box
          pos={"absolute"}
          bottom={0}
          right={0}
          display={{ base: "none", lg: "block" }}
          bg={"brand.green"}
          boxSize={"100px"}
          style={{ clipPath: "polygon(100% 0, 100% 100%,  0 100%)" }}
        ></Box>
        <Heading w={{ base: "80%", lg: "500px" }}>
          All set? Become a Merielite
        </Heading>

        <Link
          //href="https://form.typeform.com/to/L657PCoj"
          onClick={onOpen}
          //isExternal
          color="white"
          bg={"brand.green"}
          _hover={{
            textDecoration: "none",
            boxShadow: "2xl",
          }}
          py={{ base: 2, lg: 4 }}
          px={{ base: 4, lg: 8 }}
          rounded={"8"}
          fontSize="1.4rem"
          fontWeight={"bold"}
        >
          <Button
            variant={"solid"}
            bg={"brand.green"}
            py={"2.5rem"}
            color={"white"}
            w="200px"
            size={"lg"}
            _hover={{ color: "brand.textblack", boxShadow: "lg" }}
            fontWeight="bold"
            fontSize={"1.8rem"}
          >
            Apply here
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};
