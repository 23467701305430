import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import HeroImage from "./assets/hero-image.png";
import Ambassadors from "./assets/ambassadors.png";
import Navbar from "./components/Navbar";
import { FAQs } from "./components/FAQs";
import { ApplicationCTA } from "./components/CTA";
import { Footer } from "./components/Footer";
import { Benefits } from "./components/Benefits";
import Responsibilities from "./components/Responsibilities";
import WaitListModal from "./components/WaitlistModal";

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box bg={"white"}>
      <WaitListModal isOpen={isOpen} onClose={onClose} />
      <VStack
        h={{ base: "80vh", lg: "80vh" }}
        bg={"brand.lightergreen"}
        px={{ base: 2, lg: 28 }}
      >
        <Navbar />
        <HStack
          h={"100%"}
          justifyContent={{ base: "center", lg: "space-between" }}
          px={{ base: 12, lg: "0" }}
        >
          <VStack
            color={"brand.textblack"}
            w={{ base: "100%", lg: "45%" }}
            h={{ base: "100%", lg: "25rem" }}
            mr={{ base: "0px", lg: "100px" }}
            py={{ base: 6, lg: "0" }}
            //px={{ base: 12, lg: "0" }}
            fontSize={"1.4rem"}
            alignItems={{ base: "center", lg: "start" }}
            justifyContent={"space-around"}
            textAlign={{ base: "center", lg: "left" }}
          >
            <Heading
              fontSize={{ base: "2.8rem", lg: "3.2rem" }}
              textAlign={{ base: "center", lg: "left" }}
              color="brand.darkgreen"
            >
              Become a Merielite
            </Heading>
            <Text textAlign={{ base: "center", lg: "left" }}>
              The Merielite program is designed to provide students with the
              right skills to learn, grow and impart finance management skills
              to others from an early stage.
            </Text>
            <Text>
              A merielite is a brand ambassador of Meristem, it is an
              opportunity that will prepare youth for the outside world quicker,
              giving them an edge over their peers to face life after school.
            </Text>
            <Flex
              alignItems={"center"}
              alignSelf={{ base: "center", lg: "start" }}
            >
              <Link
                //href="https://form.typeform.com/to/L657PCoj"
                //isExternal
                onClick={onOpen}
                color="white"
                bg={"brand.green"}
                _hover={{
                  textDecoration: "none",
                  boxShadow: "2xl",
                }}
                py={4}
                px={8}
                rounded={"8"}
                fontSize="1.4rem"
                fontWeight={"bold"}
              >
                Apply here
              </Link>
            </Flex>
          </VStack>
          <Box
            boxSize={"450px"}
            display={{ base: "none", lg: "flex" }}
            pos="relative"
          >
            <Image
              src={HeroImage}
              alt="a guy wearing hoodie"
              pos={"absolute"}
              right="-2"
              bottom={"-36"}
            />
          </Box>
        </HStack>
      </VStack>
      <Stack
        align={"center"}
        justifyContent={"space-between"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        px={{ base: 2, lg: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Flex
          justify={"center"}
          align={"center"}
          //w={{ base: "30rem", lg: "40rem" }}
          w={{ base: "300px", lg: "560px" }}
        >
          <Box
            as="iframe"
            //boxSize={"420px"}
            w={"100%"}
            h={{ base: "200px", lg: "270px" }}
            controls
            src="https://www.youtube.com/embed/RnibOQE9Z0g"
            //poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
            alt="Big Buck Bunny"
            objectFit="contain"
            sx={{
              aspectRatio: "16/9",
            }}
          />
        </Flex>
        <Stack
          spacing={{ base: 10, md: 10 }}
          color={"brand.textblack"}
          w={{ base: "90%", lg: "50rem" }}
          p={{ base: 6, lg: "0" }}
        >
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
          >
            Give your career a headstart with the right skills
          </Heading>
          <Text fontSize={"1.4rem"}>
            Because we know how difficult it can be to get on with life after
            school, Meristem is providing an opportunity to ease the process.
            Among other benefits, develop skills relevant in today’s world
            through goal-oriented activities and mentorship.
          </Text>
        </Stack>
      </Stack>
      <Benefits />
      <Responsibilities />
      <VStack
        h={{ base: "auto", lg: "80vh" }}
        bg={"brand.lightergreen"}
        px={{ base: 2, lg: 28 }}
        py={{ base: 6, lg: 0 }}
      >
        <Stack
          h={"100%"}
          alignItems="center"
          direction={{ base: "column", md: "row" }}
        >
          <VStack
            color={"brand.textblack"}
            w={{ base: "100%", lg: "45%" }}
            h={{ base: "auto", lg: "25rem" }}
            mr={{ base: "0px", lg: "100px" }}
            p={{ base: 6, lg: "0" }}
            fontSize={"1.4rem"}
            alignItems="start"
            justifyContent={"space-between"}
            spacing={8}
            textAlign={{ base: "center", lg: "left" }}
          >
            <Heading fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}>
              What you need to become a Merielite
            </Heading>
            <UnorderedList
              spacing={3}
              textAlign="left"
              px={{ base: 8, lg: "0" }}
            >
              <ListItem>
                Be eager to add Leadership skills and take on leadership roles
              </ListItem>

              <ListItem>Be persuasive and enthusiastic about selling</ListItem>
              <ListItem>
                Be excited about creativity and innovative thinking
              </ListItem>
              <ListItem>
                Be confident in your ability to learn new skills and solve real
                world problems.
              </ListItem>

              <ListItem>Be passionate about financial education</ListItem>
            </UnorderedList>
            <Flex
              alignItems={"center"}
              alignSelf={{ base: "center", lg: "start" }}
            >
              <Link
                //href="https://form.typeform.com/to/L657PCoj"
                //isExternal
                onClick={onOpen}
                color="white"
                bg={"brand.green"}
                _hover={{
                  textDecoration: "none",
                  boxShadow: "2xl",
                }}
                py={4}
                px={8}
                rounded={"8"}
                fontSize="1.4rem"
                fontWeight={"bold"}
              >
                Apply here
              </Link>
            </Flex>
          </VStack>
          <Box boxSize={{ base: "30rem", lg: "40rem" }}>
            <Image src={Ambassadors} alt="a male and three females" />
          </Box>
        </Stack>
      </VStack>
      <FAQs />
      <ApplicationCTA />
      <Footer />
    </Box>
  );
}

export default App;
