import { Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import Promo from "../assets/promo.svg";
import People from "../assets/people.svg";
import Training from "../assets/training.svg";
import Leadership from "../assets/leadership.svg";
const Responsibilities = () => {
  return (
    <Stack alignItems={"center"}>
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
        color={"brand.textblack"}
        textAlign={{ base: "left", lg: "center" }}
      >
        What does a MeriElite do?
      </Heading>
      <Flex
        w={"80%"}
        display="grid"
        alignItems={"center"}
        justifyContent="space-around"
        gridTemplateColumns={"repeat(auto-fit, minmax(42rem, 1fr))"}
        placeItems="center"
        direction={{ base: "column", lg: "row" }}
        gap={{ base: 8, md: 10, lg: 6 }}
        py={{ base: 20, md: 28 }}
        px={{ base: 2, lg: 28 }}
        color={"brand.textblack"}
        fontSize="1.4rem"
        textAlign={"center"}
      >
        <Stack
          bg={"brand.darkgreen"}
          color="white"
          boxSize={{ base: "25rem", lg: "30rem" }}
          margin={"1rem"}
          flex={"1 0 25rem"}
          borderRadius={"2xl"}
          boxShadow="lg"
          justifyContent={"center"}
          alignItems={"center"}
          py={8}
          cursor="pointer"
          transition="all 250ms ease-in-out"
          _hover={{
            boxShadow: "0 4px 1rem -4px #000",
            transform: "translateY(-3px)",
          }}
        >
          <Image src={Promo} boxSize={{ base: "8rem", lg: "9rem" }} />
          <Text w={"60%"}>Promoting the Meristem brand.</Text>
        </Stack>
        <Stack
          bg={"brand.darkgreen"}
          color="white"
          boxSize={{ base: "25rem", lg: "30rem" }}
          margin={"1rem"}
          flex={"1 0 25rem"}
          borderRadius={"2xl"}
          boxShadow="lg"
          justifyContent={"center"}
          alignItems={"center"}
          py={8}
          cursor="pointer"
          transition="all 250ms ease-in-out"
          _hover={{
            boxShadow: "0 4px 1rem -4px #000",
            transform: "translateY(-3px)",
          }}
        >
          <Image src={Leadership} boxSize={{ base: "8rem", lg: "9rem" }} />
          <Text w={"80%"}>
            Acting as a channel of distribution for Meristem products within
            tertiary institutions and amongst peers.
          </Text>
        </Stack>
        <Stack
          bg={"brand.darkgreen"}
          color="white"
          boxSize={{ base: "25rem", lg: "30rem" }}
          margin={"1rem"}
          flex={"1 0 25rem"}
          borderRadius={"2xl"}
          boxShadow="lg"
          justifyContent={"center"}
          alignItems={"center"}
          py={8}
          cursor="pointer"
          transition="all 250ms ease-in-out"
          _hover={{
            boxShadow: "0 4px 1rem -4px #000",
            transform: "translateY(-3px)",
          }}
        >
          <Image src={People} boxSize={{ base: "8rem", lg: "9rem" }} />
          <Text w={"60%"}>
            Deepen Meristem footprints across school campuses.
          </Text>
        </Stack>
        <Stack
          bg={"brand.darkgreen"}
          color="white"
          boxSize={{ base: "25rem", lg: "30rem" }}
          margin={"1rem"}
          flex={"1 0 25rem"}
          borderRadius={"2xl"}
          boxShadow="lg"
          justifyContent={"center"}
          alignItems={"center"}
          py={8}
          cursor="pointer"
          transition="all 250ms ease-in-out"
          _hover={{
            boxShadow: "0 4px 1rem -4px #000",
            transform: "translateY(-3px)",
          }}
        >
          <Image src={Training} boxSize={{ base: "8rem", lg: "9rem" }} />
          <Text w="80%">Pitch Meristem products and service offerings </Text>
        </Stack>
      </Flex>
    </Stack>
  );
};
export default Responsibilities;
