import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Stack,
} from "@chakra-ui/react";

export const FAQs = () => {
  return (
    <Stack
      w={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      id="frequently-asked"
      color={"brand.textblack"}
      mt={{ base: "2rem", lg: "4rem" }}
      fontSize={{ base: "1.4rem", lg: "1.6rem" }}
    >
      <Heading py={"15px"} fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}>
        FAQs
      </Heading>
      <Box variant="unstyled" w={{ base: "80%", lg: "70%" }}>
        <Accordion allowToggle>
          <AccordionItem
            bg={"brand.lightgreen"}
            borderRightWidth={1}
            borderLeftWidth={1}
            borderBottomWidth={1}
            py={{ base: "10px", lg: "20px" }}
            px={{ base: "15px", lg: "30px" }}
            marginBottom={"10px"}
          >
            <h2>
              <AccordionButton
                fontSize={{ base: "1.6rem", lg: "1.8rem" }}
                _focus={{ boxShadow: "none" }}
                _expanded={{ color: "brand.green" }}
              >
                <Box flex="1" textAlign="center" fontWeight={"bold"}>
                  Who can apply?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Any student at a tertiary institution in Nigeria. the program aims
              to build a community of inspired students with diverse
              backgrounds.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem
            bg={"brand.lightgreen"}
            borderRightWidth={1}
            borderLeftWidth={1}
            borderBottomWidth={1}
            py={{ base: "10px", lg: "20px" }}
            px={{ base: "15px", lg: "30px" }}
            marginBottom={"10px"}
          >
            <h2>
              <AccordionButton
                fontSize={{ base: "1.6rem", lg: "1.8rem" }}
                _focus={{ boxShadow: "none" }}
                _expanded={{ color: "brand.green" }}
              >
                <Box flex="1" textAlign="center" fontWeight={"bold"}>
                  Do I have to pay to apply?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Nothing, it is absolutely free! 😉
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            bg={"brand.lightgreen"}
            borderRightWidth={1}
            borderLeftWidth={1}
            borderBottomWidth={1}
            py={{ base: "10px", lg: "20px" }}
            px={{ base: "15px", lg: "30px" }}
            marginBottom={"10px"}
          >
            <h2>
              <AccordionButton
                fontSize={{ base: "1.6rem", lg: "1.8rem" }}
                _focus={{ boxShadow: "none" }}
                _expanded={{ color: "brand.green" }}
              >
                <Box flex="1" textAlign="center" fontWeight={"bold"}>
                  I need more information, Who can I reach?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              For enquiries, please send an email to elite@meristemng.com
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Stack>
  );
};
