import { Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";

import Internship from "../assets/internship.svg";
import Referral from "../assets/referral.svg";
import Skills from "../assets/skills.svg";
import Startlet from "../assets/msp.svg";
import Mentorship from "../assets/mentorship.svg";
import Merchandise from "../assets/merchandise.svg";
export const Benefits = () => {
  const cardsData = [
    {
      description:
        "Opportunity to hone finance, leadership and public speaking skills",
      icon: Skills,
    },
    {
      description: "Meristem Starlet Programme consideration",
      icon: Startlet,
    },
    {
      description: "Internship and opportunity to boost career prospects",
      icon: Internship,
    },
    {
      description: "Referral bonuses",
      icon: Referral,
    },

    {
      description: "Access to mentorship and practical experiences",
      icon: Mentorship,
    },
    {
      description: "Branded items and merchandise",
      icon: Merchandise,
    },
  ];
  return (
    <Stack>
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
        color={"brand.textblack"}
        textAlign={"center"}
      >
        How will this benefit you?
      </Heading>

      <Flex
        pos={"relative"}
        align={"center"}
        overflowX="auto"
        spacing={{ base: 8, md: 10, lg: 6 }}
        py={{ base: 20, md: 28 }}
        px={{ base: 2, lg: 28 }}
        color={"brand.textblack"}
        fontSize="1.6rem"
        textAlign={"center"}
      >
        {cardsData.map(({ icon, description }) => (
          <Stack
            key={icon}
            bg={"brand.lightgreen"}
            w={"30rem"}
            h="30rem"
            margin={"1rem"}
            flex={"1 0 25rem"}
            borderRadius={"2xl"}
            boxShadow="lg"
            justifyContent={"space-around"}
            alignItems={"center"}
            px={8}
            cursor="pointer"
            transition="all 250ms ease-in-out"
            _hover={{
              boxShadow: "0 4px 1rem -4px #000",
              transform: "translateY(-3px)",
            }}
          >
            <Image src={icon} boxSize={{ base: "8rem", lg: "9rem" }} />

            <Text>{description}</Text>
          </Stack>
        ))}
      </Flex>
    </Stack>
  );
};
