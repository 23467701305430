import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brand: {
      white: "#FFFFFF",
      lightgreen: "#CEE2D9",
      lightergreen: "#eff9f4",
      green: "#008246",
      darkgreen: "#004B28",
      textblack: "#012816",
    },
  },
  fonts: {
    heading: `'Roboto', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
});
export default theme;
