import { Flex, Image, VStack, Link, useDisclosure } from "@chakra-ui/react";

import logo from "../assets/meristem-logo.svg";
import WaitListModal from "./WaitlistModal";

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        w={"100%"}
        alignItems={"center"}
        h={{ base: "auto", lg: "150px" }}
        justifyContent={"space-between"}
        py={{ base: 6, lg: 4 }}
        px={{ base: 6, lg: 0 }}
      >
        <WaitListModal isOpen={isOpen} onClose={onClose} />

        <VStack justifyContent="center">
          <Image
            src={logo}
            h={{ base: 30, lg: 45 }}
            // boxSize={45}
            objectFit="cover"
            alt="Meristem logo"
          />
        </VStack>

        <Flex
          alignItems={"center"}
          display={{ base: "none", md: "flex", lg: "flex" }}
        >
          <Link
            // href="https://form.typeform.com/to/L657PCoj"
            //isExternal
            color={{ base: "brand.green", lg: "white" }}
            bg={{ base: "transparent", lg: "brand.green" }}
            _hover={{
              textDecoration: "none",
              boxShadow: "2xl",
            }}
            onClick={onOpen}
            py={{ base: 2, lg: 4 }}
            px={{ base: 4, lg: 8 }}
            rounded={"8"}
            fontSize="1.4rem"
            fontWeight={"bold"}
          >
            Apply here
          </Link>
        </Flex>
      </Flex>
    </>
  );
}
